import { Card, Form } from 'react-bootstrap';
import { useEffect } from 'react';
import RichTextEditor from 'components/elements/new-editor/RichTextEditor';
import ModuleSelect from '../../quiz/steps/modals/ModuleSelect';
import LessonSelect from '../../quiz/steps/modals/LessonSelect';
import AileadButton from 'components/marketing/common/button/AileadButton';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';

const QuizDetail = (props) => {
    const { next, formData, handleInputChange } = props;
    const { data, loading } = useFetchData('/course');
    const { response: validationResponse, loading: loadingValidation, error: validationErrors, postData } = usePostData('/course/validate/quiz');

    const nextStep = () => {
        postData({
            "title": formData.step1.title,
            "description": formData.step1.description || '',
            "course": formData.step1.course || '',
            "module": formData.step1.module || '',
            "maxAttempts" : formData.step1.maxAttempts || '',
            "lesson": formData.step1.lesson || '',
            "type": formData.step1.type,
            "isGradable": formData.step1.isGradable,
        });
    };

    useEffect(() => {
        if (validationResponse && validationResponse.status) {
            next();
        }
    }, [validationResponse, next]);

    if (!formData || !formData.step1) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Header className="border-bottom px-4 py-3">
                    <h4 className="mb-0">Quiz Detail</h4>
                </Card.Header>
                <Card.Body>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="quiz_title">Quiz Title <Form.Text className='text-danger'>*</Form.Text></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Quiz Title"
                            id="quiz_title"
                            name="quiz_title"
                            value={formData.step1.title || ''}
                            onChange={(e) => handleInputChange('title', e.target.value)}
                        />
                        {validationErrors?.errors?.title ? (
                            <Form.Text className="col-sm-6 text-danger mb-4 mt-4">
                                {validationErrors.errors.title[0]}
                            </Form.Text>
                        ) : (
                            <>Write a 255 or less character quiz description.</>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Select Course <Form.Text className='text-danger'>*</Form.Text></Form.Label>
                        <Form.Select
                            placeholder="Select Course"
                            value={formData.step1.course || ''}
                            onChange={(e) => handleInputChange('course', e.target.value)}
                        >
                            <option value=""></option>
                            {data?.data?.unpublished?.data.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.title}
                                </option>
                            ))}
                        </Form.Select>
                        {validationErrors?.errors?.course ? (
                            <Form.Text className="col-sm-6 text-danger mb-4 mt-4">
                                {validationErrors.errors.course[0]}
                            </Form.Text>
                        ) : (
                            <>Select the course that is related to this quiz.</>
                        )}
                    </Form.Group>

                    <ModuleSelect
                        module={formData.step1.module || ''}
                        handleModule={(e) => handleInputChange('module', e.target.value)}
                        validationErrors={validationErrors}
                        courseId={formData.step1.course || ''}
                    />

                    <LessonSelect
                        module={formData.step1.lesson || ''}
                        handleModule={(e) => handleInputChange('lesson', e.target.value)}
                        validationErrors={validationErrors}
                        moduleId={formData.step1.module || ''}
                    />

					<Form.Group className="mb-3">
						<Form.Label htmlFor="pointTitle">Max Attempt</Form.Label>
						<Form.Control
							type="text"
							placeholder="Max Attempt"
							id="max_attempt"
							name="maxAttempts"
							value={formData.step1.maxAttempts}
							onChange={(e) => handleInputChange('maxAttempts', e.target.value)}
						/>
						
						{validationErrors && validationErrors.maxAttempts ? (
							<Form.Text className="col-sm-6 text-danger mb-4 mt-4">
								{validationErrors.maxAttempts[0]}
							</Form.Text>
						): (<> Number of times required by student to take this quiz if failed. <Form.Text className='text-danger'>default is 2</Form.Text> </>)}

					</Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>About Quiz</Form.Label>
                        <RichTextEditor
                            editorValue={formData.step1.description || ''}
                            onChange={(event, editor) => {
                                const newData = editor.getData();
                                handleInputChange("description", newData);
                            }}
                        />
                        <Form.Text className="text-muted">
                            Any additional information about this quiz.
                        </Form.Text>
                    </Form.Group>
                    {validationErrors?.errors?.description && (
                        <Form.Group className="mb-2">
                            <Form.Text className="col-sm-6 text-danger mb-4 mt-4">
                                {validationErrors.errors.description[0]}
                            </Form.Text>
                        </Form.Group>
                    )}
                </Card.Body>
            </Card>
            <AileadButton
                variant="primary"
                type="button"
                onClick={nextStep}
                text="Next"
                loading={loading || loadingValidation}
            />
        </>
    );
};

export default QuizDetail;
