import React from 'react';
import { Table, Button } from 'react-bootstrap';
import NewPagination from 'components/elements/advance-table/NewPagination';

const EnvironmentTable = ({
    environmentData,
    handlePageChange,
    currentPage,
    lastPage,
    refreshData,
    loading
}) => {

    return (
        <>
            <div className="table-responsive">
                <Table hover className="table-centered">
                    <thead className="table-light">
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Content Type</th>
                            <th>Path</th>
                            <th>URL</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {environmentData?.data.length > 0 ? (
                            environmentData?.data?.map((environment, index) => (
                                <tr key={environment.id}>
                                    <td>{index + 1}</td>
                                    <td>{environment.title}</td>
                                    <td>{environment.contentType}</td>
                                    <td>{environment.path}</td>
                                    <td>{environment.created_at}</td>
                                    <td class="align-middle" role='cell'>
                                        <span class={`badge bg-light-${environment.status === 'closed' ? 'danger' : 'success'} text-dark-${environment.status === 'closed' ? 'danger' : 'success'}`}>
                                            {environment.status}
                                        </span>
                                    </td>
                                </tr>
                            ))
                        ) : loading ? (
                            <tr>
                                <td colSpan="6" className="text-center">Fetching Data Please wait...</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">No Environment available.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            
            <NewPagination
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
        </>
    );
};

export default EnvironmentTable;