import React, { useState, Fragment, useEffect } from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// import custom components
import GKStepper from 'components/elements/stepper/GKStepper';
import QuizDetail from './steps/QuizDetail';
import QuestionsWithOptions from './steps/QuestionsWithOptions';
import { useFetchData } from 'hooks/useFetchData';

const EditQuiz = () => {
    const initialValue = {
        step1: {
            id: '',
            title: '',
            description: '',
            course: '',
            module: '',
            lesson: '',
            maxAttempts: '',
            isGradable: false,
            type: 'gradable',
        },
        step2: {
            questions: [
                {
                    id: '',
                    text: '',
                    type: '',
                    timer: '',
                    order: '',
                    options: []
                },
            ]
        },
    }

    const [currentStep, setCurrentStep] = useState(1);
    const { id } = useParams();
    const { data, loading, error } = useFetchData("/quiz/single/" + id);

    const [formData, setFormData] = useState(() => {
        const storedFormData = localStorage.getItem('updateQuizData');
        return storedFormData ? JSON.parse(storedFormData) : initialValue;
    });

    useEffect(() => {
        if (data && data.data) {
            const quizData = data.data;

            const initialFormData = {
                step1: {
                    id: quizData.id || '',
                    title: quizData.title || '',
                    description: quizData.description || '',
                    isGradable: quizData.isGradable || false,
                    type: quizData.type || 'gradable',
                    course: quizData.course?.id || '',
                    maxAttempts: quizData.course?.maxAttempts || '',
                    module: quizData.module?.id || '',
                    lesson: quizData.lesson?.id || '',
                },
                step2: {
                    questions: (quizData.questions || []).map((question) => ({
                        id: question.id,
                        text: question.text,
                        type: question.type,
                        timer: question.timer,
                        order: question.order,
                        options: (question.options || []).map((option) => ({
                            id: option.id,
                            content: option.content,
                            isCorrect: option.isCorrect,
                        }))
                    }))
                },
            };

            setFormData(initialFormData);
        }
    }, [data]);

    useEffect(() => {
        localStorage.setItem(
            'updateQuizData',
            JSON.stringify({ formData, currentStep })
        );
    }, [formData, currentStep]);

    const handleInputChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [`step${currentStep}`]: {
                ...prevData[`step${currentStep}`],
                [field]: value,
            },
        }));
    };

    const handleQuestionInputChange = (index, field, value) => {
        setFormData((prevFormData) => {
            const newQuestions = [...prevFormData.step2.questions];
            newQuestions[index] = {
                ...newQuestions[index],
                [field]: value,
            };
            return {
                ...prevFormData,
                step2: {
                    ...prevFormData.step2,
                    questions: newQuestions,
                },
            };
        });
    };

    const next = () => {
        setCurrentStep(currentStep === 3 ? 1 : currentStep + 1);
    };

    const previous = () => {
        setCurrentStep(currentStep === 1 ? 1 : currentStep - 1);
    };

    const steps = [
        {
            id: 1,
            title: 'Quiz Detail',
            content: (
                <QuizDetail
                    formData={formData}
                    handleInputChange={handleInputChange}
                    next={next}
                />
            )
        },
        {
            id: 2,
            title: 'Quiz Questions',
            content: (
                <QuestionsWithOptions
                    formData={formData}
                    handleInputChange={handleQuestionInputChange}
                    setFormData={setFormData}
                    next={next}
                    previous={previous}
                />
            )
        },
    ];

    return (
        <Fragment>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                        <div className="mb-3 mb-md-0">
                            <h1 className="mb-1 h2 fw-bold">Update Quizes</h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="#">Courses</Breadcrumb.Item>
                                <Breadcrumb.Item href="#">Quiz</Breadcrumb.Item>
                                <Breadcrumb.Item active>Update</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </Col>
            </Row>
            {loading ? (
                <div>Loading...</div>
            ) : error ? (
                <div>Error loading quiz data</div>
            ) : (
                <GKStepper currentStep={currentStep} steps={steps} />
            )}
        </Fragment>
    );
};

export default EditQuiz;
