import { v4 as uuid } from 'uuid';
/**
 *  All Dashboard Routes
 *
 *  Understanding name/value pairs for Dashboard routes
 *
 *  Applicable for main/root/level 1 routes
 *  icon 		: String - It's only for main menu or you can consider 1st level menu item to specify icon name.
 * 				: Object - Icon as an object added from v1.4.0.
 *
 *  Applicable for main/root/level 1 and subitems routes
 * 	id 			: Number - You can use uuid() as value to generate unique ID using uuid library, you can also assign constant unique ID for react dynamic objects.
 *  title 		: String - If menu contains childern use title to provide main menu name.
 *  badge 		: String - (Optional - Default - '') If you specify badge value it will be displayed beside the menu title or menu item.
 * 	badgecolor 	: String - (Optional - Default - 'primary' ) - Used to specify badge background color.
 *
 *  Applicable for subitems / children items routes
 *  name 		: String - If it's menu item in which you are specifiying link, use name ( don't use title for that )
 *  children	: Array - Use to specify submenu items
 *
 *  Used to segrigate menu groups
 *  grouptitle : Boolean - (Optional - Default - false ) If you want to group menu items you can use grouptitle = true,
 *  ( Use title : value to specify group title  e.g. COMPONENTS , DOCUMENTATION that we did here. )
 *
 */

// import MDI icons


export const DashboardMenu = [
	{
		id: uuid(),
		title: 'Dashboard',
		icon: 'home',
		link: '/dashboard/overview',
	},

	{
		id: uuid(),
		title: 'Courses',
		icon: 'book',
		children: [
			{ 
				id: uuid(), 
				link: '/dashboard/courses/all-courses', 
				name: 'Courses' 
			},
			{
				id: uuid(),
				link: '/dashboard/courses/courses-category',
				name: 'Category'
			},
			{
				id: uuid(),
				link: '#',
				name: 'Transcripts'
			},
			{
				id: uuid(),
				link: '#',
				name: 'Certificates'
			},
		]
	},
	{
		id: uuid(),
		title: 'Quiz',
		icon: 'clock',
		link: '/dashboard/course/quizes',
	},
	{
		id: uuid(),
		title: 'Cohort',
		icon: 'calendar',
		link: '/dashboard/cohorts',
	},
	{
		id: uuid(),
		title: 'Discussions',
		icon: 'book',
		children: [
			{ 
				id: uuid(), 
				link: '/dashboard/discussion-thread', 
				name: 'Weekly Schedule' 
			},
			{
				id: uuid(),
				link: '/dashboard/discussion',
				name: 'Discussion Forum'
			},
		]
	},
	{
		id: uuid(),
		title: 'Reviews',
		icon: 'message-square',
		link: '/dashboard/reviews',
	},
	{
		id: uuid(),
		title: 'Mails',
		icon: 'inbox',
		link: '/dashboard/mail',
	},
	{
		id: uuid(),
		title: 'Payouts',
		icon: 'refresh-cw',
		link: '#',
	},
	{
		id: uuid(),
		title: 'ENV',
		icon: 'server',
		children: [
			{ 
				id: uuid(), 
				link: '/dashboard/environments', 
				name: 'Environments',
			},
			{
				id: uuid(), 
				link: process.env.REACT_APP_JUPYTERHUB_URL, 
				name: 'Launch JupyterHub',
				newPage: true
			},
			{
				id: uuid(),
				link: process.env.REACT_APP_VSCODE_URL,
				name: 'Launch Vscode',
				newPage: true
			}
		]
	},

	{
		id: uuid(),
		title: 'User',
		icon: 'user',
		children: [
			{ id: uuid(), link: '#', name: 'Instructor' },
			{ id: uuid(), link: '/dashboard/user/students', name: 'Students' }
		]
	},
	{
		id: uuid(),
		title: 'Currency',
		icon: 'credit-card',
		link: '/dashboard/currencies/'
	},
	{
		id: uuid(),
		title: 'Plan',
		icon: 'check',
		link: '/dashboard/plans'
	},
	{
		id: uuid(),
		title: 'Payments',
		icon: 'file',
		link: '/dashboard/payments'
	},
	{
		id: uuid(),
		title: 'Settings',
		grouptitle: true
	},
	{
		id: uuid(),
		title: 'Site Settings',
		icon: 'tools',
		children: [
			{ id: uuid(), link: '/dashboard/settings/general', name: 'General' },
			{ id: uuid(), link: '/dashboard/settings/google', name: 'Google' },
			{ id: uuid(), link: '/dashboard/settings/cohort', name: 'Cohort' },
			{ id: uuid(), link: '/dashboard/settings/social', name: 'Social' },
			{ id: uuid(), link: '/dashboard/settings/payment', name: 'Payment' },
			{ id: uuid(), link: '/dashboard/settings/sites', name: 'Sites' },
			{ id: uuid(), link: '/dashboard/settings/site/team', name: 'team' },
			{ id: uuid(), link: '/dashboard/settings/site/faq', name: 'FAQs' }
		]
	},
];


export const DashboardBusinessMenu = [
	{
		id: uuid(),
		title: 'Dashboard',
		icon: 'home',
		link: '/dashboard/business/analytics', 
		name: 'Analytics'
	},
	{
		id: uuid(),
		title: 'Courses',
		icon: 'book',
		link: '/dashboard/business/courses',
	},
	{
		id: uuid(),
		title: 'Payments',
		icon: 'icon-money',
		link: '/dashboard/business/payments'
	},
	{
		id: uuid(),
		title: 'Enroll',
		icon: 'icon-money',
		link: '/dashboard/business/enroll'
	},
	{
		id: uuid(),
		title: 'Subscription',
		icon: 'icon-money',
		link: '/dashboard/business/subscriptions'
	},
	{
		id: uuid(),
		title: 'Employees',
		icon: 'users',
		link: '/dashboard/business/employees'
	},
];

export const DashboardTrainerMenu = [
	{
		id: uuid(),
		title: 'Earning',
		icon: 'home',
		link: '/dashboard/trainer/earning',
	},
	{
		id: uuid(),
		title: 'Course',
		icon: 'book',
		link: '/dashboard/trainer/courses',
	},
	{
		id: uuid(),
		title: 'Quiz',
		icon: 'book',
		link: '/dashboard/trainer/quizzes',
	},
	{
		id: uuid(),
		title: 'Payouts',
		icon: 'icon-money',
		link: '/dashboard/trainer/payouts'
	},
	{
		id: uuid(),
		title: 'Students',
		icon: 'icon-money',
		link: '/dashboard/trainer/students'
	},
];

export default DashboardMenu;
